import LPGlider from 'ui/glider';

const gliderOptions = {
	slidesToShow: 8,
	slidesToScroll: 8,
	dots: false,
	rewind: false,
	duration: 0,
	arrows: {
		prev: '#clearanceSortWrapper .glider-prev',
		next: '#clearanceSortWrapper .glider-next'
	},
	responsive: [
		{
			// screens greater than >= 1280
			breakpoint: 1280,
			settings: {
				slidesToShow: 12,
				slidesToScroll: 12
			}
		}
	]
};
const gliderContainer = document.querySelector('.clearanceSort');

if (gliderContainer) {

	gliderContainer.addEventListener('glider-loaded', function () {

		const glider = LPGlider(this);
		glider.setActiveSlide(0);
		this.parentElement.classList.remove('glider-loading');

	});

	new LPGlider(gliderContainer, gliderOptions);

}
